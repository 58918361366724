import React, { useState } from 'react';
import './navbar.css'
import { RxHamburgerMenu } from 'react-icons/rx'
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
    }

    return (
        <div className='hamburger-menu'>
            <div className='hamburger' onClick={toggleHamburger}>
                <RxHamburgerMenu size={35} color='#ff4e00' />
            </div>
            <div className={hamburgerOpen ? 'navigationOn' : 'navigationOff'}>
                <ul className='items'>
                    <Link to='/'><li onClick={toggleHamburger} className='links'>HOME</li></Link>
                    <Link to='/contact'><li onClick={toggleHamburger} className='links'>CONTACT US</li></Link>
                    <Link to='/resources'><li onClick={toggleHamburger} className='links'>RESOURCES + HELP</li></Link>
                </ul>
            </div>

        </div>
    );
};

export default Navbar;