import React from 'react'
import './resources.css'

const resources = () => {
    return (
        <>
            {/* section 1 */}
            <div className="divider2"></div>
            <div className="contactus-section">
                <div className="container">
                    <h2 className="heading heading3"> Resources + Help</h2>
                    <h2 className='section-heading'>Emergency Resources</h2>
                    <h3 className="part-heading">Triple Zero (000)</h3>
                    <p className='part-resource'>Phone: <b>000</b></p>
                    <ul><li>In case of emergency or immediate danger. National phone number for Police, Ambulance or Fire.</li></ul>

                    <h3 className="part-heading">Lifeline</h3>
                    <p className='part-resource'>Phone: <b>13 11 44</b></p>
                    <ul><li>24/7 confidential crisis support service available from a landline, payphone or mobile. Anyone across Australia experiencing a personal crisis or thinking about suicide can contact Lifeline. </li></ul>

                    <h3 className="part-heading">1800 RESPECT</h3>
                    <div>
                        <a className='part-resource' href="https://1800respect.org.au" target='_blank' rel='noreferrer'>1800respect.org.au</a>
                    </div>

                    <p className='part-resource'>Phone: <b>1800 737 732</b></p>
                    <ul><li>National sexual assault, domestic and family violence counselling service for people living in Australia. Confidential 24/7 telephone helpline and online counselling service.</li></ul>
                </div>
            </div>
            {/* section 2 */}
            <div className="divider4"></div>
            <div className="acknowledgements-section">
                <div className="container">
                    <h2 className='section-heading'>Mental Health Resources</h2>
                    <p className='section-description'>If you require urgent help or are in immediate distress, please phone Triple Zero (000) or contact your local hospital emergency department.</p>
                    <h3 className="part-heading-red">Lifeline</h3>
                    <p className='part-resource'>Phone: <b>13 11 44</b></p>
                    <ul><li>24/7 confidential crisis support service available from a landline, payphone or mobile. Anyone across Australia experiencing a personal crisis or thinking about suicide can contact Lifeline. </li></ul>
                    <h3 className="part-heading-red">Beyond Blue</h3>
                    <div>
                        <a className='part-resource' href="https://beyondblue.org.au" target='_blank' rel='noreferrer'>beyondblue.org.au</a>
                    </div>
                    <ul><li>Support and resources for concerns about depression and anxiety.  </li></ul>
                    <p className='part-resource'>Support line: <b>1300 224 636</b></p>
                    <ul><li>24/7 information and advice from a trained mental health professional. </li></ul>
                    <h3 className="part-heading-red">SANE Australia</h3>
                    <ul><li>Information about mental health and illness including treatment and support.</li></ul>
                    <p className='part-resource'>Helpline: <b>1800 18 7263</b></p>
                    <ul><li>For mental health information and referral to support services. Available 9am – 5pm weekdays.</li></ul>
                </div>
            </div>
            {/* section 3 */}
            <div className="divider3"></div>
            <div className="contactus-section">
                <div className="container">
                    <h2 className='section-heading'>Relationship Resources</h2>
                    <h3 className="part-heading">Family Relationships Online</h3>
                    <div>
                        <a className='part-resource' href="https://familyrelationships.gov.au" target='_blank' rel='noreferrer'>familyrelationships.gov.au</a>
                    </div>
                    <ul><li>Provides all families (whether together or separated) with access to information about family relationship issues, ranging from building better relationships to dispute resolution.</li></ul>

                    <h3 className="part-heading">Relationships Australia</h3>
                    <div>
                        <a className='part-resource' href="https://relationships.org.au" target='_blank' rel='noreferrer'>relationships.org.au</a>
                    </div>
                    <ul><li>A leading provider of relationship support services for individuals, families and communities, with service providers in each Australian state and territory and a national office based in Canberra.</li></ul>
                    <h3 className="part-heading">Family Relationship Advice Line</h3>
                    <p className='part-resource'>Phone: <b>1800 050 321</b></p>
                    <ul><li>A national telephone service established to assist anyone affected by relationship or separation issues, including parents, grandparents, children, young people, step-parents or friends. Available 8am – 8pm Monday to Friday, and 10am – 4pm Saturday (except national public holidays).</li></ul>
                </div>
            </div>
            {/* section 4 */}
            <div className="divider4"></div>
            <div className="acknowledgements-section">
                <div className="container">
                    <h2 className='section-heading'>Parenting Resources</h2>
                    <p className='part-resource'><b>National Resources</b></p>
                    <h3 className="part-heading-red">Raising Children Network</h3>
                    <div>
                        <a className='part-resource' href="https://raisingchildren.net.au" target='_blank' rel='noreferrer'>raisingchildren.net.au</a>
                    </div>
                    <ul><li>The complete Australian resource for parenting newborns to teens. Information and discussion forums for Australian mothers, fathers, grandparents and anyone else who has responsibility for the care of children.</li></ul>
                    <h3 className="part-heading-red">Resourcing Parents</h3>
                    <div>
                        <a className='part-resource' href="https://resourcingparents.nsw.gov.au" target='_blank' rel='noreferrer'>resourcingparents.nsw.gov.au</a>
                    </div>
                    <ul><li>Provides parenting education information to parents and carers of children aged 0 to 18 years.</li></ul>
                    <h3 className="part-heading-red">Pregnancy, Birth and Baby</h3>
                    <p className='part-resource'>Phone: <b>1800 882 436</b></p>
                    <ul><li>Available 24/7.</li></ul>
                    <div>
                        <a className='part-resource' href="https://pregnancybirthbaby.org.au" target='_blank' rel='noreferrer'>pregnancybirthbaby.org.au</a>
                        <ul><li>Receive guidance on children’s growth, behaviour and development, or talk to a counsellor for emotional support. Video call available 7am – midnight, 7 days (including public holidays).</li></ul>
                    </div>
                    <h3 className="part-heading-red">Kids Helpline</h3>
                    <p className='part-resource'>Phone: <b>1800 55 1800</b></p>
                    <ul><li>Australia's only free, private and confidential, phone counselling service specifically for young people aged between 5 and 25. Website also includes information and support for parents. Available 24/7.</li></ul>
                    {/* State resources */}
                    <p className='part-resource'><b>State Resources</b></p>
                    {/* Northern Capital Territory */}
                    <p className='state-resource-title'>Australian Capital Territory</p>
                    <p className='part-resource'><b>Parentline ACT</b></p>
                    <p className='part-resource'>Phone: <b>(02) 6287 3833</b></p>
                    <ul><li>A not-for-profit community organisation providing the only telephone counselling service for parents and carers in the ACT.  Available 9am – 5pm, Monday to Friday (except public holidays)</li></ul>
                    {/* New South Wales */}
                    <p className='state-resource-title'>New South Wales</p>
                    <p className='part-resource'><b>Parent Line</b></p>
                    <p className='part-resource'>Phone: <b>1300 130 052</b></p>
                    <ul><li>A telephone counselling, information and referral service for parents, grandparents and carers of children aged 0 to 18 years who live in New South Wales.
                        Includes the Early Childhood Intervention Infoline, providing information and support for concerns about child development, disabilities and developmental delays.
                        Available 9am – 9pm, Monday to Friday, and 4pm – 9pm Saturday.</li></ul>
                    <p className='part-resource'><b>Karitane Careline</b></p>
                    <p className='part-resource'>Phone: <b>1300 227 464</b></p>
                    <ul><li>Consultation on a wide range of issues concerning infants and children from 0 to 5 years of age. Available 12.30pm – 9pm & 11pm – 6.30am, Monday to Thursday, and 9am – 3.30pm, Friday to Sunday.</li></ul>
                    <p className='part-resource'><b>Tresillian Parent Helpline</b></p>
                    <p className='part-resource'>Phone: <b>1300 272 736 </b></p>
                    <ul><li>Available 7am – 11pm, 7 days </li></ul>
                    <div>
                        <a className='part-resource' href="https://tresillian.org.au" target='_blank' rel='noreferrer'>tresillian.org.au</a>
                        <ul><li>Parenting service offering families guidance in the early years of their child's life. Tresillian live online advice service available 5pm – 11pm, Monday to Friday.</li></ul>
                    </div>
                    <p className='part-resource'><b>NSW Family & Community Services </b></p>
                    <div>
                        <a className='part-resource' href="https://community.nsw.gov.au/parents,-carers-and-families" target='_blank' rel='noreferrer'>community.nsw.gov.au/parents,-carers-and-families</a>
                        <ul><li>Resources for parents, carers and families.</li></ul>
                    </div>
                    <p className='part-resource'><b>NSW Family Referral Service</b></p>
                    <div>
                        <a className='part-resource' href="https://familyreferralservice.com.au" target='_blank' rel='noreferrer'>familyreferralservice.com.au</a>
                        <ul><li>Works with NSW services to provide referral to the right support services for young people, families with children, and extended family members.</li></ul>
                    </div>
                    <p className='part-resource'><b>Child Behaviour Research Clinic</b></p>
                    <div className='link-resource'>
                        <a className='part-resource' href="https://sydney.edu.au/science/psychology/child_behaviour_research_clinic" target='_blank' rel='noreferrer'>sydney.edu.au/science/psychology/child_behaviour_research_clinic</a>
                        <ul><li>A treatment, teaching and research clinic based at the University of Sydney, providing state-of-the-art treatments for young children with behavioural and emotional problems. Assists mothers and fathers to learn better strategies for managing their child's behavioural difficulties.</li></ul>
                    </div>
                    {/* Northern Territory  */}
                    <p className='state-resource-title'>Northern Territory</p>
                    <p className='part-resource'><b>Parentline Queensland and Northern Territory </b></p>
                    <p className='part-resource'>Phone: <b>1300 301 300 </b></p>
                    <ul><li>A confidential telephone service providing professional counselling and support for parents and carers of children in Queensland and the Northern Territory. Available 8am – 10pm, 7 days</li></ul>
                    {/* Queensland */}
                    <p className='state-resource-title'>Queensland</p>
                    <p className='part-resource'><b>Parentline Queensland and Northern Territory </b></p>
                    <p className='part-resource'>Phone: <b>1300 301 300 </b></p>
                    <ul><li>A confidential telephone service providing professional counselling and support for parents and carers of children in Queensland and the Northern Territory. Available 8am – 10pm, 7 days</li></ul>
                    {/* South Australia */}
                    <p className='state-resource-title'>South Australia</p>
                    <p className='part-resource'><b>Parent Helpline</b></p>
                    <p className='part-resource'>Phone: <b>1300 364 100</b></p>
                    <ul><li>A confidential telephone service providing professional counselling and support for parents and carers of children in Queensland and the Northern Territory. Available 8am – 10pm, 7 days</li></ul>
                    {/* Tasmania */}
                    <p className='state-resource-title'>Tasmania</p>
                    <p className='part-resource'><b>Parentline Tasmania</b></p>
                    <p className='part-resource'>Phone: <b>1300 808 178</b></p>
                    <ul><li>Available 24/7 to assist parents of children 0-5 years with stressful parenting issues or concerns.</li></ul>
                    {/* Victoria */}
                    <p className='state-resource-title'>Victoria</p>
                    <p className='part-resource'><b>Parentline Victoria</b></p>
                    <p className='part-resource'>Phone: <b>132 289</b></p>
                    <ul><li>A statewide telephone counselling service for parents and carers of children aged from 0 to 18 years. Available 8am – midnight, 7 days.</li></ul>
                    <p className='part-resource'><b>Maternal and Child Health Advisory Line</b></p>
                    <p className='part-resource'>Phone: <b>132 229</b></p>
                    <ul><li>24/7 statewide telephone service available to Victorian families with children from birth to school age.</li></ul>
                    {/* Western Australia */}
                    <p className='state-resource-title'>Western Australia</p>
                    <p className='part-resource'><b>Parenting WA Line</b></p>
                    <p className='part-resource'>Phone: <b>(08) 6279 1200</b> (metropolitan) or <b>1800 654 432</b> (regional callers)</p>
                    <ul><li>Provides telephone information, support and referrals to parents, carers, grandparents,
                        foster carers and families who are taking care of a child or children aged from pre-birth to 18 years. Available 24/7.</li></ul>
                    <p className='part-resource'><b>Grandcare</b></p>
                    <p className='part-resource'>Phone: <b>1800 008 323</b></p>
                    <ul><li>Information service for grandparents. Available 10am – 3pm, Monday to Friday.</li></ul>
                    <p className='part-resource'><b>Ngala Helpline </b></p>
                    <p className='part-resource'>Phone: <b>(08) 9368 9368</b> (metropolitan) or <b>1800 111 546</b> (regional callers) </p>
                    <ul><li>Contact point for parents with babies and young children who are seeking to discuss their parenting concerns. Available 8am – 8pm, 7 days.</li></ul>
                </div>
            </div>
            {/* Resources For Fathers */}
            <div className="divider3"></div>
            <div className="contactus-section">
                <div className="container">
                    <h2 className='section-heading'>Resources For Fathers</h2>
                    <h3 className="part-heading">MensLine Australia</h3>
                    <p className='part-resource'>Phone: <b>1300 78 99 78</b></p>
                    <div>
                        <a className='part-resource' href="https://mensline.org.au/" target='_blank' rel='noreferrer'>mensline.org.au/</a>
                    </div>
                    <ul><li>24/7 national telephone and online support, information and referral service for men with family and relationship concerns.
                        The service is available from anywhere in Australia and is staffed by professional counsellors experienced in men’s issues. </li></ul>

                    <h3 className="part-heading">Dads in Distress</h3>
                    <p className='part-resource'>Phone: <b>1300 853 457</b></p>
                    <ul><li>Available 9am – 5pm AEST, Monday to Friday.</li></ul>
                    <div>
                        <a className='part-resource' href="https://dadsindistress.asn.au" target='_blank' rel='noreferrer'>dadsindistress.asn.au</a>
                    </div>
                    <ul><li>Provides peer support to parents at risk of crisis and trauma that may impact upon family safety and wellbeing.</li></ul>

                    <h3 className="part-heading">Beyond Blue</h3>
                    <div>
                        <a className='part-resource' href="https://beyondblue.org.au/" target='_blank' rel='noreferrer'>beyondblue.org.au/</a>
                    </div>
                    <ul><li>Includes support and resources for male mental health.</li></ul>
                    <p className='part-resource'>Support line: <b>1300 22 4636 </b></p>
                    <ul><li>24/7 information and advice from a trained mental health professional.</li></ul>

                    <h3 className="part-heading">Mens Referral Service (NSW and VIC)</h3>
                    <p className='part-resource'>Phone: <b>1300 766 491</b></p>
                    <div>
                        <a className='part-resource' href="https://mrs.org.au/" target='_blank' rel='noreferrer'>mrs.org.au</a>
                    </div>
                    <ul><li>Offers anonymous and confidential telephone counselling, information and referrals to help men stop using violent and controlling behaviour.</li></ul>

                    <h3 className="part-heading">Men and Family Relationship Services</h3>
                    <div className='link-resource'>
                        <a className='part-resource' href="https://familyrelationships.gov.au/BrochuresandPublications/Pages/FRSP_bro1_v2.aspx" target='_blank' rel='noreferrer'>familyrelationships.gov.au/BrochuresandPublications/Pages/FRSP_bro1_v2.aspx</a>
                    </div>
                    <ul><li>Aims to help men and their family members to develop and maintain positive family relationships, especially at times of relationship change. Services are located in every state and territory in a range of rural, regional and metropolitan locations.
                    </li></ul>

                    <h3 className="part-heading">Parenting Research Centre – Fathers Matter Booklet</h3>
                    <div className='link-resource'>
                        <a className='part-resource' href="https://education.vic.gov.au/childhood/parents/support/Pages/fatherspublication.aspx" target='_blank' rel='noreferrer'>education.vic.gov.au/childhood/parents/support/Pages/fatherspublication.aspx</a>
                    </div>
                    <ul><li>Practical information for fathers about bonding with your children, rough and tumble play, and parenting after separation.</li></ul>
                </div>
            </div>
        </>

    )
}

export default resources