import React from 'react'
import './contact.css'

const contact = () => {
    return (
        <>
            <div className='contact'>
                <div className='contact-details'>
                    <h1 className='heading heading2'>Contact Us</h1>
                    <p>If you have any questions or want to contact the Research team about ParentWorks program, please get in touch. We will aim to respond to your query within a week. </p>
                    
                    <p><a className='email' href='mailto:parent_works@sydney.edu.au'>Email: parent_works@sydney.edu.au</a></p>
                </div>
            </div>
            {/* commented out form in case of future builds */}
            {/* <form className='contactUs'> */}


            {/* <p className='emailP'>Email: parent_works@sydney.edu.au</p>
                <label htmlFor="name">Name:</label>
                <input autoFocus
                    type="text"
                    id='name'
                    placeholder='Name'
                    required
                />
                <label htmlFor="email">Email:</label>
                <input autoFocus
                    type="text"
                    id='email'
                    placeholder='Email'
                    required
                />
                <label htmlFor="Enquiry">Enquiry:</label>
                <textarea className='enquiry' autoFocus
                    type="text"
                    id='enquiry'
                    placeholder='Enquiry'
                    required
                />
                <button type='submit' className='submitBtn'>SUBMIT</button> */}
            {/* </form> */}
        </>

    )
}

export default contact