// The commented out code was programmed for the original home page.
// In place in case we want to revert back to the original home page.

// import { Outlet, Link } from "react-router-dom";
import './home.scss';
import fatherImage from '../assets/the-father-effect.png'
import kidsFighting from '../assets/kids-fighting.png'
// import movemberLogo from '../assets/movember-logo.png'
// import { AiFillFacebook, AiFillYoutube, AiOutlineMail, AiFillTwitterSquare } from 'react-icons/ai';
import parentWorksVideo from '../../src/parentWorks.mp4';
import usydLogo from '../assets/usyd-logo-1.png'

export default function Home() {
    return (
        <>
            <div className='landing-container'>
                <div className='logo-heading-container'>
                    <h1 className='logo-heading'>ParentWorks</h1>
                    <p className='logo-title'>Parenting that works for you.</p>
                    {/* <div className='btn-container'>
                        <button className="btn-primary">PARTICIPATE</button>
                    </div> */}
                </div>
            </div>
            <div className='divider1'></div>
            <div className='about-section'>
                <div className='container'>
                    <h2 className='heading heading1'>What is ParentWorks?</h2>
                    <div className='description-video-container'>
                        <div className='description-container'>
                            <p>ParentWorks is a <b>free</b> online program for Australian parents and caregivers of children 2 to 17. It is an <b>‘evidence-based’</b> program,
                                which means that the program has been tested in research and found it be effective in improving parenting skills, parenting confidence and child behaviour. </p><br />
                            <p>Parents and caregivers may find this program helpful for:</p>
                            <ul><li><p>Managing challenging child behaviours such as tantrums, aggression, noncompliance, inattentive or hyperactive behaviour, sibling conflict, getting ready for
                                school and/or bed, and behaviours outside the home, such as problems in the supermarket</p></li></ul>
                            <ul><li><p>Increasing their confidence in parenting</p></li></ul>
                            <ul><li><p>Working as a team with their partner</p></li></ul>
                            <p>ParentWorks is entirely self-directed, which means that you work through the program on your own and there is no assistance from a professional during this program. </p>
                        </div><br />
                        <img className='kids-fighting' src={kidsFighting} alt="kids fighting" />
                        {/* <div className='video-container'>
                            <video width="490" height="281" controls="controls" autoplay="true" />
                            <p>Professor Mark Dadds from the University of Sydney talks about ParentWorks</p>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='divider2'></div>
            <div className='program-section'>
                <div className='container'>
                    <h2 className='heading heading2'>ParentWorks is a father friendly program</h2>
                    <div className='program-container'>
                        <img className='father-image' src={fatherImage} alt="Father" />
                        <div className='father-description-container'>
                            <p>ParentWorks has been developed by a team of psychologists and researchers at the University of Sydney.</p>
                            <p>We know from research that parenting programs are more effective when all parents are involved. We also know
                                that fathers are less likely to participate in parenting programs than mothers, for a whole range of reasons.
                                One of these reasons is that most parenting programs have not been developed with dads in mind!
                            </p>
                            <p>We have developed ParentWorks to be a father friendly program and encourage all fathers to take part.</p>
                            {/* <div className='father-video-section'>
                            <iframe width="286" height="160" src="https://www.youtube.com/embed/Jz__it37Gng" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> 
                            <iframe className='video-right' width="286" height="160" src="https://www.youtube.com/embed/UJMLxNtA6nA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className='btn-container'>
                                <button className="btn-secondary">LEARN MORE</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider3"></div>
            <div className="program-for-section">
                <div className="container">
                    <h2 className="heading heading1">Who is the program for?</h2>
                    <div className='description-video-container'>
                        <div className='description-container'>
                            <p>This program is for parents and caregivers of a child aged 2-17 years. The program only allows one parent per family to participate.
                                However, we recommend that both parents (in two-parent families) complete the program together to increase consistent parenting practices.
                                To do this, you can either set up separate accounts and watch the videos separately, or you can complete the program together from one parent’s account.</p><br />
                            <p>Practitioners who wish to view the program in order to recommend it to families can do so. You will be asked at the start of the program if you are a parent
                                or a practitioner, so please select the practitioner option at the start of the program.</p>
                        </div><br />


                    </div>
                </div>
            </div>
            <div className="divider2"></div>
            <div className="involved-section">
                <div className="container">
                    <h2 className="heading heading2">What is involved?</h2>
                    <div className='description-video-container'>
                        <div className='description-container'>
                            <p>ParentWorks involves 5 to 8 ‘modules’ to watch – one per week. Each module only takes about 20-30 minutes of your time and involves videos with practical information and activities. </p><br />
                            <p>You can work through the program in your own time and at your own pace. The program takes time and effort, but the rewards will be worth it. </p><br />
                            <p>Participating parents will be asked to complete a brief measure at the start and end of the program about their child’s emotions and behaviours, and will then be
                                provided with automated feedback on these questionnaires.
                                They will also be asked questions at the end of the program to find out about their perceptions of the program. </p><br />
                            <p>Participating practitioners will be asked to complete a few questions about themselves at the start of the program, and questions at the end to find out their perceptions of the program.</p><br />
                            <p>All data collected from parents and practitioners is used only by researchers at the University of Sydney to monitor the program’s effectiveness. All data is collected via Cogniss, a third-party web-app.
                                University of Sydney researchers download the de-identified data and it is stored securely in password protected files. Once downloaded, the data is then deleted from Cogniss servers.
                                This data will not be used for research purposes.</p><br />
                            <p>For a more detailed summary, please see <a href='https://www.cogniss.com/privacy-policy' target='_blank' rel="noreferrer">https://www.cogniss.com/privacy-policy</a></p>
                        </div><br />
                        <video controls width='50%' height='50%'>
                            <source type='video/mp4' src={parentWorksVideo} />
                        </video>
                    </div>
                </div>
            </div>
            <div className="divider3"></div>
            <div className="program-for-section">
                <div className="container">
                    <h2 className="heading heading1">What does the program cover?</h2>
                    <div className='description-video-container'>
                        <div className='description-container'>
                            <p>ParentWorks can be completed in 4 to 8 weeks and includes 5 core modules and three optional modules.
                                Each module contains: video footage of a leading child psychologist talking about practical strategies caregivers can use,
                                video demonstrations of the strategies, as well as exercises to complete both during and after the video modules. Each module takes around 20 to 30 minutes to complete.</p>
                            <h3>Topics covered in the core modules: </h3>
                            <ol>
                                <li>Module 1: Introduction to ParentWorks</li>
                                <li>Encouraging Positive Behaviour </li>
                                <li>Responding to Misbehaviour</li>
                                <li>Managing Challenging Situations and Sibling Conflict </li>
                                <li>Review and Preventing Future Problems</li>
                            </ol>
                            <h3>Optional modules include: </h3>
                            <ol>
                                <li>Working as a Team</li>
                                <li>Encouraging Child Development through Quality Time and Play </li>
                                <li>Bully-proofing Your Child</li>
                            </ol>
                        </div><br />
                    </div>
                </div>
            </div>
            <div className="divider2"></div>
            <div className="involved-section">
                <div className="container">
                    <h2 className="heading heading2">Other resources</h2>
                    <div className='description-video-container'>
                        <div className='description-container'>
                            <p>You’ll also find other useful resources on the website as you progress through the program, like information sheets on:</p>
                            <ul>
                                <li>Discipline Strategies for Older Children and Teens</li>
                                <li>Tips for Separated and Divorced Parents </li>
                                <li>Other problems children experience like sleep difficulties, low mood and worrying</li>
                                <li>Screen Time</li>
                                <li>Children’s Social Skills and Assertive Communication </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider3"></div>
            <div className="program-for-section">
                <div className="container">
                    <h2 className="heading heading1">Research</h2>
                    <div className='research-container'>

                        <p>ParentWorks has been tested in research and found to be effective. The research study with 456 parents found that the program led to significant pre-to-post program decreases
                            in child emotional/behaviour problems, ineffective parenting, conflict between parents and parent mental health problems (Piotrowska et al., 2020).
                            For more information about the research conducted on ParentWorks, please see the following peer-reviewed journal articles:</p>
                        <br />
                        <i>Piotrowska, P.J., Tully, L.A., Collins, D.A., Sawrikar, V., Hawes, D., Kimonis, E.R., Lenroot, R.K., Moul, C., Anderson, V., Frick, P.J. and Dadds, M.R., 2020. ParentWorks:
                            Evaluation of an online, father-inclusive, universal parenting intervention to reduce child conduct problems. Child Psychiatry & Human Development, 51, pp.503-513.</i><br /><br />
                        <i>Dadds, M.R., Sicouri, G., Piotrowska, P.J., Collins, D.A., Hawes, D.J., Moul, C., Lenroot, R.K., Frick, P.J., Anderson, V., Kimonis, E.R. and Tully, L.A., 2019. Keeping parents involved:
                            predicting attrition in a self-directed, online program for childhood conduct problems. Journal of Clinical Child & Adolescent Psychology, 48(6), pp.881-893.</i><br /><br />
                        <i>Collins, D.A., Tully, L.A., Piotrowska, P.J., Hawes, D.J. and Dadds, M.R., 2019. Perspectives on ParentWorks: Learnings from the development and national roll-out of a self-directed online
                            parenting intervention. Internet Interventions, 15, pp.52-59.</i><br /><br />
                        <i>Tully, L.A., Piotrowska, P.J., Collins, D.A., Mairet, K.S., Hawes, D.J., Kimonis, E.R., Lenroot, R.K., Moul, C., Anderson, V., Frick, P.J. and Dadds, M.R., 2017. Study protocol: evaluation of an online,
                            father-inclusive, universal parenting intervention to reduce child externalising behaviours and improve parenting practices. BMC psychology, 5, pp.1-11.</i>

                    </div>
                </div>
            </div>
            <div className="divider2"></div>
            <div className="testimonials-socials-section">
                <div className="container">
                    <h2 className="heading heading2">Testimonials</h2>
                    <div className="testimonial-container">

                        <p><span className="testimonial-heading">Mother of 12-year-old girl</span><br />“I was provided with clear information and guidance to help minimise challenging behaviour and to reestablish a good relationship with my daughter.
                            The methods truly worked. Over the weeks of the program there was a great decrease in difficult behaviour and a huge improvement in the connection between my daughter and me”.</p>

                        <p><span className="testimonial-heading">Mother of 7-year-old boy</span><br />“In our experience, the modules were very well structured, self-explanatory and easy to understand. The presenter delivered the information in a calm,
                            clear and reassuring manner. We were able to print off the modules in order to undertake our homework, use the flow charts as a ready reckoner on a day to day basis,
                            and constantly refresh our memories whenever we hit a stumbling block. Armed with this content, we have gained increased confidence and consistency in our parenting abilities”.</p>
                    </div>
                    {/* <div className="socials-container">
                        <p>FIND US ON</p>
                        <a href="https://www.facebook.com/parentworksaustralia" alt='Find us on Facebook' target='_blank' rel='noreferrer'><AiFillFacebook size={40} className="facebook" /></a>
                        <a href="https://www.youtube.com/channel/UCdXmbjMLo3ad0EKpnwy1CEg" alt='Youtube Channel' target='_blank' rel="noreferrer"><AiFillYoutube size={40} className="youtube" /></a>
                        <p>SHARE</p>
                        <a alt='send email link' target='_blank' rel="noreferrer" href="mailto:?&subject=ParentWorks online program&body=I%20wanted%20to%20share%20the%20www.parentworks.org.au%20site%20with%20you.%20%0A%0AParentWorks%20is%20a%20free%20online%20program%20for%20Australian%20parents%20and%20caregivers%20of%20children%202%20to%2016.%20"><AiOutlineMail size={40} className="email" /></a>
                        <a href="https://www.facebook.com/sharer/sharer.php?u=www.parentworks.org.au" alt='share on facebook' target='_blank' rel="noreferrer"><AiFillFacebook size={40} className="facebook" /> </a>
                        <a href="https://twitter.com/home?status=www.parentworks.org.au" alt='share on twitter' target='_blank' rel="noreferrer"><AiFillTwitterSquare size={40} className="twitter" /></a>

                    </div> */}
                    {/* <h2 className="bottom-heading">HOW DO I PARTICIPATE IN PARENTWORKS?</h2>
                    <div className="description">If you would like to participate in the program please click here:</div>
                    <div className='btn-container'>
                        <button className="btn-primary">PARTICIPATE</button>
                    </div>
                    <div className="footer-logo-container">
                        <img src={usydLogo} alt="usyd-logo" className='usydLogo' />
                        <img src={movemberLogo} alt="movember-logo" className='movemberLogo' />
                    </div> */}
                </div>
            </div>
            <div className="divider4"></div>
            <div className="acknowledgements-section">
                <div className="container">
                    <h3 className="heading heading3">Acknowledgements</h3>
                    <h4 className="heading heading3">Funding</h4>
                    <p>The ParentWorks research project was proudly funded by the Movember Foundation </p>
                    <h2 className="heading heading3">Website Development</h2>
                    <p>The ParentWorks website was created by the team from Cogniss </p>
                    <h2 className="heading heading3">Video Production</h2>
                    <p>Video production was undertaken by the team at Ten Alphas </p>
                    <h2 className="heading heading3">Creative and Media Agencies</h2>
                    <p>Marmalade and FRANK provided assistance with the creative and media aspects of ParentWorks. </p>
                    <h2 className="heading heading3">Other</h2>
                    <ul>
                        <li>With thanks to Raising Children’s Network for providing several clips seen in ParentWorks.
                            <a rel='noreferrer' href='https://raisingchildren.net.au' target='_blank'><i className="link-children">Visit raisingchildren.net.au for free parenting videos, apps and articles backed by Australian experts.</i></a>
                        </li><br />
                        <li>With thanks to Essential Media for providing footage from the Kids on Speed ABC series</li>< br />
                        <li>Our thanks also to the fathers and mothers who provided input into the development of ParentWorks. </li>
                    </ul>
                </div>
            </div>
            <div className='divider3'></div>
            <div className='footer-logo'>
                <div className="usyd-logo-container">
                    <h3>Developed by</h3>
                    <a href="https://www.sydney.edu.au/">
                        <img style={{width: "auto", height: "100%"}} src={usydLogo} alt="University of Sydney"/>
                    </a>
                </div>
            </div>
        </>
    );
}