import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import './index.css';
import Home from './routes/home'
import Contact from './routes/contact'
import ErrorPage from './components/errorPage';
import Resources from './routes/resources';
import { FiLogIn } from 'react-icons/fi'
import { BsFillPencilFill } from 'react-icons/bs'
import NavBar from './components/NavBar'
import usydLogo from './assets/usyd-logo-1.png'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <Router>
      <nav>
        <NavBar className='burger' />
        <NavLink className='desktop-links' to='/'>HOME</NavLink>
        <NavLink className='desktop-links' to='/contact'>CONTACT US</NavLink>
        <NavLink className='desktop-links-end' to='/resources'>RESOURCES + HELP</NavLink>
        <a className='user-action-container' href='https://parentworks.usyd.cogniss.com' target='_blank' rel='noreferrer' >
          <span className='icon'><BsFillPencilFill size={15} />Sign-Up</span>
          <span className='or'>or</span><span className='icon'><FiLogIn size={15} />Login</span>
        </a>
      </nav>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/resources' element={<Resources />}></Route>
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </Router>
  </>
);





